/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - contact page.
 */

// Core dependencies
import React from "react";

// App dependencies
import ContactUs from "../components/contact/contactUs";
import Layout from "../components/layout";

// Styles
import { container } from "../templates/contentTemplate.module.css";

class Contact extends React.Component {
  render() {
    return (
      <Layout>
        <div className={container}>
          <ContactUs tagHeading={"h1"} />
        </div>
      </Layout>
    );
  }
}

export default Contact;
